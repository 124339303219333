<template>
  <div>
    <h1
      class="text-4xl font-heading-1 pb-4 pl-2 font-extrabold tracking-tight text-gray-900"
    >
      {{ $t("Payment methods") }}
    </h1>
    <Table
      :columns="columns"
      :data="formattedPaymentMethods"
      :is-updatable="true"
      @update="redirectUpdatePaymentMethodLink"
    />
  </div>
</template>

<script>
import PlanService from "@/api/services/plan";
import Table from "@/components/utils/Table.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Table,
  },
  data: () => {
    return {
      paymentMethods: [],
      columns: [
        { label: "Numéro de contrat", field: "custom_id", bold: true },
        { label: "Moyen de paiement", field: "paymentMethod" },
      ],
    };
  },
  computed: {
    formattedPaymentMethods() {
      return this.paymentMethods.map((method) => ({
        ...method,
        paymentMethod: {
          icon: method.type === "card" ? "CreditCardIcon" : "HomeIcon",
          label: `${method.brand} **** ${method.digits}`,
        },
      }));
    },
  },
  created() {
    this.retrievePaymentMethods();
  },
  methods: {
    ...mapActions("notifications", ["notify"]),

    async retrievePaymentMethods() {
      try {
        const response = await PlanService.getPaymentMethods();
        this.paymentMethods = response.data.payment_methods;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong.",
          text: "Please try again or contact the support.",
        });
      }
    },
    async redirectUpdatePaymentMethodLink(plan) {
      try {
        const response = await PlanService.getUpdatePaymentMethodLink(plan.id);
        window.open(response.data.update_link, '_blank');
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong.",
          text: "Please try again or contact the support.",
        });
      }
    },
  },
};
</script>
