<template>
  <div class="overflow-x-auto">
    <table class="table-auto min-w-full divide-y divide-gray-300 text-xs">
      <thead class="bg-gray-100 border-t border-gray-300">
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            scope="col"
            :class="[
              'px-3 py-2 text-left text-black font-bold tracking-wide whitespace-nowrap w-auto',
              column.numeric ? 'text-right' : '',
            ]"
          >
            {{ column.label }}
          </th>
          <th
            v-if="isDeletable || isUpdatable"
            class="px-3 py-2 text-left text-black font-bold tracking-wide whitespace-nowrap w-auto"
          ></th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(row, rowIndex) in data" :key="rowIndex">
          <td
            v-for="(column, cellIndex) in columns"
            :key="cellIndex"
            :class="getCellClass(column)"
          >
            <span v-if="typeof row[column.field] === 'object'" class="flex items-center space-x-2">
              <component
                v-if="row[column.field].icon"
                :is="iconComponents[row[column.field].icon]"
                class="h-5 w-5 text-primary-dark"
              />
              <span v-html="row[column.field].label"></span>
            </span>
            <span v-else v-html="formatValue(row[column.field], column)"></span>
          </td>
          <td v-if="isDeletable">
            <TrashIcon class="h-5 cursor-pointer" @click="() => $emit('delete', row)" />
          </td>
          <td v-if="isUpdatable">
            <PencilAltIcon class="h-5 cursor-pointer" @click="() => $emit('update', row)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { CreditCardIcon, HomeIcon, TrashIcon, PencilAltIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CreditCardIcon,
    HomeIcon,
    TrashIcon,
    PencilAltIcon,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    isUpdatable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconComponents: {
        CreditCardIcon,
        HomeIcon,
      },
    };
  },
  methods: {
    formatValue(value, column) {
      if (!value) {
        return "—";
      }
      if (typeof value === "number" && column.price) {
        return this.formatPrice(value);
      }
      return value;
    },
    getCellClass(column) {
      let baseClass = "px-3 py-2 whitespace-nowrap font-light";
      if (column.bold) {
        baseClass += " text-black font-semibold";
      }
      if (column.numeric) {
        baseClass += " text-right";
      }
      return baseClass;
    },
  },
};
</script>
