<template>
  <div class="overflow-hidden max-w-xl mx-auto rounded-lg bg-white shadow">
    <div class="px-4 py-5 sm:p-6">
      <ClaimForm :plan_id="this.plan.id" :start_date="this.startDate" :claimProductId="this.claimProductId" />
    </div>
  </div>
</template>

<script>
import ClaimForm from "@/components/ClaimForm.vue";
import { mapState } from "vuex";

export default {
  components: {
    ClaimForm,
  },

  created() {
    this.setPlan();
    this.claimProductId = parseInt(this.$route.params.claimProductId);
  },

  data() {
    return {
      plan: {},
      startDate: {},
      claimProductId: null,
    };
  },

  methods: {
    setPlan: function () {
      this.plan = this.plans.find((plan) => plan.id === parseInt(this.$route.params.id));
      this.startDate = this.plan.startDate;
    },
  },

  computed: {
    ...mapState("customer", ["plans"]),
  },
};
</script>
