<template>
  <div>
    <h1 class="text-xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">Assurance</h1>

    <ErrorBanner v-if="planIsSuspended" error-level="error">
      {{ $t("Your contract has been suspended, please settle your situation from the Invoices tab") }}
    </ErrorBanner>

    <ErrorBanner v-else-if="!plan.paymentsUpToDate" error-level="error">
      {{ $t("You are not up to date with your payments, please settle your situation from the Invoices tab") }}
    </ErrorBanner>

    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="bg-white px-4 py-5 sm:px-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <img class="max-h-24 max-w-24 object-contain" v-bind:src="`${plan.imageUrl}`" alt="" />
              </div>
              <div class="ml-4">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ plan.name }}
                </h3>
                <p class="text-sm text-gray-500">
                  <a href="#">{{ plan.vendor }}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="w-full sm:w-auto sm:ml-4 mt-4 flex-shrink-0 flex flex-row items-center justify-end">
            <a
              :href="plan.marketingPage"
              target="_blank"
              class="relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <DocumentTextIcon class="-ml-1 mr-2 h-5 w-5 text-button-font-primary" aria-hidden="true" />
              <span>{{ $t("View terms") }}</span>
            </a>
            <Menu as="div" class="ml-3 relative" v-if="cancellationAvailable">
              <div>
                <MenuButton
                  class="max-w-xs flex items-center text-sm rounded-full focus:outline-none hover:bg-gray-200"
                  @click="openSelector"
                >
                  <span class="sr-only">Actions</span>
                  <div class="h-8 w-8 rounded-full flex items-center justify-center">
                    <DotsVerticalIcon class="text-gray-500 w-6" />
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem>
                    <router-link
                      v-bind:to="`/plans/${plan.id}/cancellation`"
                      class="relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium border border-transparent text-red-900 hover:bg-red-200"
                      v-if="cancellationAvailable"
                    >
                      <BanIcon class="-ml-1 mr-2 h-5 w-5 text-white-400" aria-hidden="true" />
                      <span>{{ $t("Cancel my contract") }}</span>
                    </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Order ID") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">{{ plan.orderId }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Purchase date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">{{ plan.purchaseDate }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Contract start date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">{{ plan.startDate }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Expires on") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">{{ plan.expiresOn }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Warranty Price") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatPrice(plan.warrantyPrice) }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Warranty Term") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">{{ plan.warrantyTerm }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">
              {{ $t("Status") }}
            </dt>
            <dd class="mt-1">
              <span
                v-bind:class="[
                  plan.status === 'Active' ? 'text-green-800 bg-green-100' : 'text-gray-800 bg-gray-100',
                  'px-2 py-1 text-xs font-medium rounded-full',
                ]"
              >
                {{ $t(plan.status) }}
              </span>
            </dd>
          </div>
        </dl>
        <div class="mt-10" v-if="plan.maxInsurableCombinedPrice">
          <p class="text-gray-700 my-2">
            Vous utilisez actuellement
            <span class="font-bold">{{ composedItemsPrice.replace(/\./g, ",") }}€</span>
            sur {{ plan.maxInsurableCombinedPrice }}€ disponibles
          </p>
          <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              class="h-6 bg-primary rounded-full dark:bg-primary"
              :style="`width: ${composedItemsPricePercentage}%`"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/price_formatter";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { DocumentTextIcon, BanIcon, DotsVerticalIcon } from "@heroicons/vue/solid";
import ErrorBanner from "@/components/utils/ErrorBanner.vue";

export default {
  props: ["plan"],

  computed: {
    cancellationAvailable() {
      return this.plan.status === "Active" && this.plan.cancellationAvailable;
    },
    composedItemsPrice() {
      if (this.plan && this.plan.product && this.plan.product.length > 0) {
        const total = this.plan.product.reduce((total, product) => {
          const price = parseFloat(product.price.replace(",", "."));
          return total + (isNaN(price) ? 0 : price);
        }, 0);
        return total.toFixed(2);
      } else {
        return 0;
      }
    },
    composedItemsPricePercentage() {
      if (this.plan && this.plan.maxInsurableCombinedPrice > 0) {
        const percentage = (parseFloat(this.composedItemsPrice) / this.plan.maxInsurableCombinedPrice) * 100;
        return percentage.toFixed(2);
      }
      return 0;
    },

    planIsSuspended() {
      return this.plan.status == "Suspended";
    },
  },

  components: {
    ErrorBanner,
    DocumentTextIcon,
    BanIcon,
    DotsVerticalIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  methods: {
    formatPrice,
    openSelector() {
      this.isSelectorOpen = !this.isSelectorOpen;
    },
  },
};
</script>
