<template>
  <div>
    <div>
      <legend class="text-lg font-medium text-gray-900">
        {{ this.getQuestion }}
      </legend>
      <form>
        <div class="mt-4 sm:col-span-6">
          <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div class="space-y-1 text-center">
              <svg
                class="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div class="flex text-sm text-gray-600">
                <label
                  :id="`file-${this.question.id}`"
                  class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary"
                >
                  <span>{{ $t("Upload a file") }}</span>
                  <input
                    :id="`file-${this.question.id}`"
                    type="file"
                    class="sr-only"
                    multiple
                    :placeholder="this.question.description"
                    v-on:change="this.handleFileUpload"
                  />
                </label>
                <p class="pl-1">{{ $t("or drag and drop") }}</p>
              </div>
              <p class="text-xs text-gray-500">
                {{ $t("PNG, JPG, GIF up to 10MB") }}
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div v-if="this.isValid" class="mt-4 grid grid-cols-1 gap-1 place-items-center">
    <legend class="text-lg font-medium text-gray-900">
      {{ $t("Uploaded files") }}
    </legend>

    <div class="border-t border-gray-200 px-4 py-5 sm:p-0 flex" v-for="(file, index) in files" v-bind:key="file">
      <span class="mr-2">{{ file.name }}</span>
      <button @click="removeFile(index)">
        <TrashIcon class="h-5 w-5 text-primary" aria-hidden="true" />
      </button>
    </div>
  </div>

  <div v-if="this.isCheckboxRequirementPresent" class="mt-5">
    <div class="items-center mb-4">
      <input
        id="checkbox"
        type="checkbox"
        v-model="this.checkboxRequirementsAccepted"
        class="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label for="checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {{ this.checkboxContent.text }}
      </label>
    </div>
  </div>

  <div>
    <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
      {{ $t("Please attach at least one file and check the box above") }}
    </p>
  </div>

  <FormNavigationButton :isFirstStep="this.isFirstStep" @next="next" @previous="previous" />
</template>

<script>
import FormNavigationButton from "@/views/customer/claim/form/navigation/FormNavigationButton";
import { getTranslatedQuestionIfNeeded } from "@/views/customer/claim/form/utils/translation-functions";
import { TrashIcon } from "@heroicons/vue/solid";

export default {
  components: {
    FormNavigationButton,
    TrashIcon,
  },

  props: ["question", "possibleResponses", "isFirstStep", "currentResponse", "checkboxContent"],

  data() {
    return {
      files: this.currentResponse != null ? this.currentResponse : null,
      validResponse: true,
      checkboxRequirementsAccepted: false,
    };
  },

  computed: {
    isCheckboxRequirementPresent() {
      return this.checkboxContent !== undefined && this.checkboxContent !== null;
    },
    isCheckboxRequirementAccepted() {
      return this.isCheckboxRequirementPresent ? this.checkboxRequirementsAccepted : true;
    },
    isValid() {
      return this.files !== null && this.files.length !== 0;
    },
    getSingleResponseId() {
      return this.possibleResponses[0].id;
    },
    getQuestion() {
      return getTranslatedQuestionIfNeeded(this.question, this.$i18n.locale);
    },
  },

  methods: {
    next: function () {
      if (this.isValid && this.isCheckboxRequirementAccepted) {
        this.validResponse = true;
        this.$emit("next", this.files, this.getSingleResponseId);
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },

    handleFileUpload: function (event) {
      if (this.files === null) {
        this.files = [];
      }
      const fileList = event.target.files;

      for (let i = 0; i < fileList.length; i++) {
        this.files.push(fileList[i]);
      }

      event.target.value = null;
    },

    removeFile: function (index) {
      return this.files.splice(index, 1);
    },
  },

  watch: {
    question: {
      handler(newQuestion, oldQuestion) {
        if (newQuestion.id !== oldQuestion.id) {
          console.log("files", this.files);
          this.files = [];
        }
      },
      deep: true,
    },
  },
};
</script>
