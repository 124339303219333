<template>
  <div v-bind:style="{ 'margin-top': '2%' }">
    <div>
      <div class="mt-5">
        <div class="items-center mb-4">
          <input
            id="checkbox"
            type="checkbox"
            v-model="this.checkboxValidated"
            class="w-4 h-4 text-primary-dark bg-gray-100 border-gray-300 rounded focus:ring-primary dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label for="checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Je confirme que les déclarations ci-dessus sont exactes
          </label>
        </div>
      </div>
    </div>

    <div>
      <p v-if="!this.validResponse" class="my-2 text-sm text-red-600">
        {{ $t("Please check the box above") }}
      </p>
    </div>

    <div class="flex items-center justify-center">
      <button
        class="inline-flex items-center py-2 px-4 mr-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        @click="this.previous"
      >
        <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        {{ $t("Back") }}
      </button>
      <button
        type="submit"
        class="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-button-font-primary bg-primary uppercase focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        v-bind:disabled="processing"
        @click="this.submitClaim"
      >
        <svg
          class="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          v-show="processing"
        >
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>

        {{ processing ? $t("Processing...") : $t("Submit Claim") }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 ml-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    processing: Boolean,
  },

  data() {
    return {
      checkboxValidated: false,
      validResponse: true,
    };
  },

  methods: {
    submitClaim: function () {
      if (this.checkboxValidated) {
        this.validResponse = true;
        this.$emit("submit");
      } else {
        this.validResponse = false;
      }
    },

    previous: function () {
      this.$emit("previous");
    },
  },
};
</script>
