function capitalizeFirstLetter(string) {
  return string !== null ? string.charAt(0).toUpperCase() + string.slice(1) : null;
}

function camelCaseToWords(string) {
  return string
    .replace(/([A-Z]+)/g, " $1")
    .replace(/([A-Z][a-z])/g, "$1")
    .toLowerCase();
}

export { capitalizeFirstLetter, camelCaseToWords };
