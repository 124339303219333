<template>
  <div>
    <div v-if="this.isQuestionType('checkbox') && !this.isSummaryStep">
      <CheckboxStep
        :question="this.currentClaimQuestion"
        :possibleResponses="this.currentPossibleResponses"
        :isFirstStep="this.isFirstStep"
        :currentResponse="this.getCurrentResponse(this.currentClaimFormStep.id)"
        @next="next"
        @previous="previous"
      />
    </div>

    <div v-if="this.isQuestionType('date') && !this.isSummaryStep">
      <DateStep
        :question="this.currentClaimQuestion"
        :possibleResponses="this.currentPossibleResponses"
        :isFirstStep="this.isFirstStep"
        :currentResponse="this.getCurrentResponse(this.currentClaimFormStep.id)"
        :startDate="this.startDate"
        @next="next"
        @previous="previous"
      />
    </div>

    <div v-if="this.isQuestionType('textarea') && !this.isSummaryStep">
      <TextAreaStep
        :key="this.currentClaimQuestion.id"
        :question="this.currentClaimQuestion"
        :possibleResponses="this.currentPossibleResponses"
        :isFirstStep="this.isFirstStep"
        :currentResponse="this.getCurrentResponse(this.currentClaimFormStep.id)"
        @next="next"
        @previous="previous"
      />
    </div>

    <div v-if="this.isQuestionType('file') && !this.isSummaryStep">
      <FileStep
        :question="this.currentClaimQuestion"
        :possibleResponses="this.currentPossibleResponses"
        :isFirstStep="this.isFirstStep"
        :currentResponse="this.getCurrentResponse(this.currentClaimFormStep.id)"
        :checkboxContent="this.currentClaimFormStep.checkbox_validation"
        @next="next"
        @previous="previous"
      />
    </div>
    <div v-if="this.isQuestionType('address') && !this.isSummaryStep">
      <AdressStep
        :question="this.currentClaimQuestion"
        :possibleResponses="this.currentPossibleResponses"
        :isFirstStep="this.isFirstStep"
        :currentResponse="this.getCurrentResponse(this.currentClaimFormStep.id)"
        @next="next"
        @previous="previous"
      />
    </div>
    <div>
      <ClaimFormSummary v-if="this.isSummaryStep" :responses="this.formResponses" @previous="previous" />
    </div>
    <SummaryNavigationButton
      v-if="this.isSummaryStep"
      :processing="this.processing"
      @previous="this.previous"
      @submit="this.submitClaim"
    ></SummaryNavigationButton>
  </div>
</template>

<script>
import ClaimService from "../api/services/claim";
import { mapMutations, mapActions } from "vuex";
import CheckboxStep from "@/views/customer/claim/form/steps/CheckboxStep";
import DateStep from "@/views/customer/claim/form/steps/DateStep";
import TextAreaStep from "@/views/customer/claim/form/steps/TextAreaStep";
import FileStep from "@/views/customer/claim/form/steps/FileStep";
import ClaimFormSummary from "@/views/customer/claim/form/summary/ClaimFormSummary";
import { getTranslatedQuestionIfNeeded } from "@/views/customer/claim/form/utils/translation-functions";
import SummaryNavigationButton from "@/views/customer/claim/form/navigation/SummaryNavigationButton";
import AdressStep from "@/views/customer/claim/form/steps/AdressStep.vue";
import moment from "moment/moment";

export default {
  components: {
    AdressStep,
    CheckboxStep,
    DateStep,
    TextAreaStep,
    FileStep,
    ClaimFormSummary,
    SummaryNavigationButton,
  },

  props: {
    plan_id: {
      type: Number,
      required: true,
    },
    start_date: {
      type: String,
      required: true,
    },
    claimProductId: {
      type: String,
      required: true,
    },
  },

  created() {
    this.setPlan();
    this.getClaimForm();
  },

  data() {
    return {
      claimFormSteps: [],
      claimQuestions: [],
      claimResponses: [],
      currentClaimFormStep: {},
      currentClaimQuestion: {},
      currentPossibleResponses: [],
      formResponses: {},
      plan: {},
      previousClaimFormStepStack: [],
      processing: false,
      startDate: {},
      isSummaryStep: false,
    };
  },

  computed: {
    isFirstStep() {
      return this.previousClaimFormStepStack.length === 0;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    setPlan: function () {
      this.startDate = moment(this.start_date, "DD/MM/YYYY").toDate();
    },

    getQuestionById: function (questionId) {
      return this.claimQuestions.find((question) => question.id === questionId);
    },

    getClaimFormStepById: function (claimFormStepId) {
      return this.claimFormSteps.find((claimFormStep) => claimFormStep.id === claimFormStepId);
    },

    getResponseById: function (responseId) {
      return this.claimResponses.find((response) => response.id === responseId);
    },

    getCurrentPossibleResponses: function () {
      const possibleResponses = this.currentClaimFormStep.possible_responses;

      let responses = [];

      possibleResponses.forEach((possibleResponse) => {
        const response_id = possibleResponse.response_id;
        responses.push(this.getResponseById(response_id));
      });

      return responses;
    },

    getClaimForm: async function () {
      try {
        this.setIsLoading(true);

        // Parsing response data
        let response = await ClaimService.getClaimForm(this.plan_id, this.claimProductId);
        let responseContent = response.data.array;
        let formContent = responseContent.form_content;

        // Variables representing form data
        this.claimFormSteps = formContent.claim_form_steps;
        this.claimQuestions = formContent.questions;
        this.claimResponses = formContent.responses;

        this.updateCurrentFormStepData(formContent.claim_form_steps[0]);

        this.setIsLoading(false);
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
        }
      }
    },

    next: function (response, response_id) {
      this.formResponses[this.currentClaimFormStep.id] = {
        id: this.currentClaimFormStep.id,
        response: response,
        response_id: response_id,
        referenced_question: this.currentClaimQuestion,
        referenced_response: this.getResponseById(response_id),
        question: getTranslatedQuestionIfNeeded(this.currentClaimQuestion, this.$i18n.locale),
        question_translations: this.currentClaimQuestion.__translation,
        type: this.currentClaimFormStep.question_type,
      };

      this.previousClaimFormStepStack.push(this.currentClaimFormStep);

      const nextFormStepId = this.getNextFormStepId(response_id);

      if (nextFormStepId == null) {
        this.isSummaryStep = true;
      } else {
        this.updateCurrentFormStepData(this.getClaimFormStepById(nextFormStepId));
      }
    },

    previous: function () {
      if (this.isSummaryStep) {
        this.isSummaryStep = false;
      }

      if (this.previousClaimFormStepStack.length !== 0) {
        const previousClaimFormStep = this.previousClaimFormStepStack.pop();
        delete this.formResponses[previousClaimFormStep.id];
        this.updateCurrentFormStepData(previousClaimFormStep);
      } else {
        this.updateCurrentFormStepData(this.claimFormSteps[0]);
      }
    },

    submitClaim: async function () {
      this.processing = true;
      const formData = new FormData();

      let answers = {};

      for (const formStepId in this.formResponses) {
        const responseContent = this.formResponses[formStepId];
        switch (responseContent.type) {
          case "date":
            answers[formStepId] = {
              question: responseContent.referenced_question,
              response: responseContent.referenced_response,
              response_value: responseContent.response,
              type: responseContent.type,
            };
            break;
          case "checkbox":
            answers[formStepId] = {
              question: responseContent.referenced_question,
              response: responseContent.referenced_response,
              response_value: responseContent.response,
              type: responseContent.type,
            };
            break;
          case "file":
            var fileList = responseContent.response;

            var responseValue = [];

            for (var i = 0; i < fileList.length; i++) {
              let file = fileList[i];
              const fileIndex = `${formStepId}_${i}`;
              responseValue.push({
                name: file.name,
                index: fileIndex,
              });

              formData.append("files[" + fileIndex + "]", file);
            }

            answers[formStepId] = {
              question: responseContent.referenced_question,
              response: responseContent.referenced_response,
              response_value: responseValue,
              type: responseContent.type,
            };

            break;
          case "textarea":
            answers[formStepId] = {
              question: responseContent.referenced_question,
              response: responseContent.referenced_response,
              response_value: responseContent.response,
              type: responseContent.type,
            };
            break;
        }
      }

      formData.append("answers", JSON.stringify(answers));
      try {
        this.setIsLoading(true);
        await ClaimService.createClaim(this.plan_id, this.claimProductId, formData);
        this.setIsLoading(false);
        this.notify({
          category: "simple",
          type: "success",
          title: "Your claim has been submitted!",
          text: "It's being reviewed by our team and you will hear back from a representative within 1-2 business days.",
        });
        this.$router.push("/plans");
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 422:
            this.notify({
              category: "simple",
              type: "error",
              title: "Something went wrong.",
              text: error.response.data.error,
            });
        }
      }
    },

    updateCurrentFormStepData: function (newClaimFormStep) {
      this.currentClaimFormStep = newClaimFormStep;
      const currentQuestionId = this.currentClaimFormStep.question_id;
      this.currentClaimQuestion = this.getQuestionById(currentQuestionId);
      this.currentPossibleResponses = this.getCurrentPossibleResponses();
    },

    isQuestionType: function (type) {
      return this.currentClaimFormStep.question_type === type;
    },

    getNextFormStepId: function (responseId) {
      let result = null;
      if (responseId !== null) {
        const next_step_id = this.currentClaimFormStep.possible_responses.find(
          (response) => response.response_id === responseId
        ).next_step_id;
        if (next_step_id !== null && next_step_id !== "undefined") {
          result = next_step_id;
        }
      }

      if (result == null) {
        result = this.currentClaimFormStep.default_next_step_id;
      }

      return result;
    },

    getCurrentResponse: function (formStepId) {
      const currentStepResponse = this.formResponses[formStepId];
      return currentStepResponse != null ? currentStepResponse.response : null;
    },
  },
};
</script>
