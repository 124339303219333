<template>
  <div v-bind:style="{ 'margin-top': '4%' }">
    <div class="flex items-center justify-center">
      <div v-if="!this.isFirstStep">
        <a
          v-on:click="previousStep"
          href="#"
          class="inline-flex items-center py-2 px-4 mr-3 text-sm text-button-font-primary bg-primary uppercase"
        >
          <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          {{ $t("Back") }}
        </a>
      </div>
      <div class="FormButton">
        <a
          v-on:click="nextStep"
          href="#"
          class="inline-flex items-center py-2 px-4 text-sm text-button-font-primary bg-primary uppercase"
        >
          {{ $t("Next") }}
          <svg class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFirstStep: Boolean,
  },

  methods: {
    nextStep: function () {
      this.$emit("next");
    },

    previousStep: function () {
      this.$emit("previous");
    },
  },
};
</script>
