<template>
  <div>
    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0 scale-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-700"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-100"
    >
      <div class="h-24 w-full bg-primary-light">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full">
          <div class="flex justify-between items-center h-full">
            <div class="flex-shrink-0 flex items-center px-4">
              <a href="https://www.estaly.co/">
                <img class="h-12 w-auto" src="@/assets/large_logo_estaly_black.png" alt="Estaly" />
              </a>
            </div>
            <div class="flex justify-end">
              <router-link to="/signin">
                <button
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary uppercase"
                >
                  {{ $t("Log In") }}
                </button>
              </router-link>
            </div>
            <transition
              enter-active-class="transition-opacity duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-700"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            ></transition>
          </div>
        </div>
      </div>
    </transition>
    <div class="py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="bg-white shadow px-4 py-6 sm:rounded-lg sm:p-10 md:px-48">
        <ClaimForm :plan_id="parseInt(this.$route.params.id)" :start_date="this.startDate" />
      </div>
    </div>
  </div>
</template>

<script>
import ClaimForm from "../components/ClaimForm.vue";

export default {
  components: {
    ClaimForm,
  },

  computed: {
    startDate: function () {
      return new Date(Date.parse(this.$route.params.date));
    },
  },
};
</script>
