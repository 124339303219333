<template>
  <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("Contract id") }}
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("Amount") }}
              </th>
              <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">
                {{ $t("Status") }}
              </th>
              <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">
                {{ $t("Due date") }}
              </th>
              <th scope="col" class="py-3 pl-4 text-left text-sm font-semibold text-gray-900">
                {{ $t("Invoice") }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="planSaleBilling in this.planSaleBillings" :key="planSaleBilling.id">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                {{ planSaleBilling.planSaleCustomId }}
              </td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                {{ planSaleBilling.finalPrice }}
              </td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                <FieldSpan
                  v-if="planSaleBilling.uncollectible == true"
                  :styleToAdd="getPaymentStatusFieldDisplayStyle('uncollectible')"
                  value="uncollectible"
                />
                <FieldSpan
                  v-else
                  :styleToAdd="getPaymentStatusFieldDisplayStyle(planSaleBilling.paymentStatus)"
                  :value="planSaleBilling.paymentStatus"
                />
              </td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                {{ planSaleBilling.startDate }}
              </td>
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                <div v-if="planSaleBilling.uncollectible === true" class="flex">
                  <div class="bg-gray-100 rounded-xl flex px-2 py-1.5">
                    <BanIcon class="h-6 w-6 text-gray-800" aria-hidden="true" />
                  </div>
                </div>
                <div v-else-if="planSaleBilling.discountApplied" class="flex">
                  <GiftIcon class="h-6 w-6 text-primary-dark" aria-hidden="true" />
                  <span class="pl-2 text-base font-semibold text-primary-dark">{{ $t("Free") }}</span>
                </div>
                <div v-else-if="planSaleBilling.paymentStatus === 'succeeded'" class="flex">
                  <CloudDownloadIcon
                    v-if="planSaleBilling.invoiceUrl"
                    class="h-6 w-6 text-primary-dark cursor-pointer"
                    aria-hidden="true"
                  />
                  <a
                    v-if="planSaleBilling.invoiceUrl"
                    @click.prevent="handleDownload(planSaleBilling)"
                    class="pl-2 text-base font-semibold text-primary-dark cursor-pointer"
                    target="_blank"
                  >
                    {{ $t("Download") }}
                  </a>
                </div>
                <div v-else-if="['failed', 'disputed'].includes(planSaleBilling.paymentStatus)" class="flex">
                  <div class="bg-secondary rounded-xl flex px-2 py-1.5">
                    <CreditCardIcon
                      v-if="planSaleBilling.invoicePaymentUrl"
                      class="h-6 w-6 text-black cursor-pointer"
                      aria-hidden="true"
                    />
                    <a
                      v-if="planSaleBilling.invoicePaymentUrl"
                      @click.prevent="handlePayment(planSaleBilling)"
                      class="flex items-center pl-2 text-base font-semibold text-black uppercase cursor-pointer"
                      target="_blank"
                    >
                      {{ $t("Pay") }}
                    </a>
                  </div>
                </div>
                <div v-else class="px-3 py-0.5 rounded-full text-sm font-medium bg-secondary">
                  <p>{{ $t("Available soon") }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { CloudDownloadIcon, GiftIcon, CreditCardIcon, BanIcon } from "@heroicons/vue/outline";
import CustomerService from "@/api/services/customer";

export default {
  components: {
    FieldSpan,
    CloudDownloadIcon,
    GiftIcon,
    CreditCardIcon,
    BanIcon,
  },

  props: ["planSaleBillings"],

  methods: {
    getPaymentStatusFieldDisplayStyle: function (paymentStatus) {
      switch (paymentStatus) {
        case "waiting":
          return "bg-yellow-100 text-yellow-800";
        case "succeeded":
          return "bg-green-100 text-green-800";
        case "failed":
        case "disputed":
          return "bg-red-100 text-red-800";
        case "uncollectible":
          return "bg-gray-100 text-gray-800";
        default:
          return "";
      }
    },

    handleDownload(planSaleBilling) {
      CustomerService.getPlanSaleBillingInvoice(planSaleBilling.id)
        .then((response) => {
          const url = response.data.invoiceUrl;
          if (url) window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching the invoice URL:", error);
        });
    },

    handlePayment(planSaleBilling) {
      CustomerService.getPlanSaleBillingInvoice(planSaleBilling.id)
        .then((response) => {
          const url = response.data.invoicePaymentUrl;
          if (url) window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching the payment URL:", error);
        });
    },
  },
};
</script>
