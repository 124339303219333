<template>
  <footer class="bg-soft-gray text-lg" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="flex flex-col items-end sm:flex-row">
      <div class="mx-auto px-6">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="mt-16 grid grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
            <div class="mt-0">
              <h3 class="font-semibold leading-6 text-gray-900">Aide et services</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.helpAndServices" :key="item.name">
                  <a :href="item.href" target="_blank" class="leading-6 text-gray-600 text-sm hover:text-gray-900">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="font-semibold leading-6 text-gray-900">À propos de nous</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.about" :key="item.name">
                  <a :href="item.href" target="_blank" class="leading-6 text-gray-600 text-sm hover:text-gray-900">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="pt-8 text-xs text-gray-500">
          Vous bénéficiez d'un droit de renonciation à ce contrat pendant un délai de trente jours (calendaires) à
          compter de la souscription, sans frais ni pénalités. Toutefois, si vous bénéficiez d’une ou de plusieurs
          primes d’assurance qui vous sont offertes, de telle sorte que vous n’avez pas à payer une prime sur un ou
          plusieurs mois au début d’exécution du contrat, ce délai ne court qu’à compter du paiement de tout ou partie
          de la première prime. L’exercice du droit de renonciation est subordonné aux quatre conditions suivantes : 1.
          Vous avez souscrit ce contrat à des fins non professionnelles ; 2. Ce contrat vient en complément de l'achat
          d'un bien ou d'un service vendu par un fournisseur ; 3. Le contrat auquel vous souhaitez renoncer n'est pas
          intégralement exécuté ; 4. Vous n'avez déclaré aucun sinistre garanti par ce contrat. Dans cette situation,
          vous pouvez exercer votre droit à renoncer à ce contrat en nous adressant une lettre ou tout autre support
          durable. Nous sommes tenus de vous rembourser la prime payée dans un délai de trente jours à compter de votre
          renonciation. Par ailleurs, pour éviter un cumul d’assurances, vous êtes invité à vérifier que vous n'êtes pas
          déjà bénéficiaire d'une garantie couvrant l'un des risques garantis par le contrat que vous avez souscrit.
        </p>
        <p class="pt-4 text-xs text-gray-500">
          ESTALY, Société de courtage en assurance au capital de 1 500 € Immatriculée au RCS de PARIS sous le n° 909 874
          109, inscrit à l’ORIAS sous le numéro 22 002 508 (www.orias.fr), dont le siège social est situé 24 rue Malar –
          75007 Paris, (garantie financière et responsabilité civile professionnelle conformes aux Articles L512-6 et
          L512-7 du Code des Assurances).
        </p>
      </div>
      <img class="h-96" src="@/assets/large_logo_estaly_alternative.png" alt="Estaly" />
    </div>
  </footer>
</template>

<script setup>
const navigation = {
  legalDocuments: [
    { name: "Politique de confidentialité des données", href: "#" },
    { name: "Réclamation", href: "mailto:contact@estaly.co" },
  ],
  helpAndServices: [
    { name: "Déclarer un sinistre", href: "https://customer.estaly.co/file-a-claim" },
    { name: "Nous contacter", href: "mailto:contact@estaly.co" },
  ],
  about: [{ name: "About", href: "https://www.estaly.co" }],
};
</script>
