import { plainAxiosInstance } from "../axios";

import { CLAIM_URL } from "./constants";

class ClaimService {
  retrieve({ planParams }) {
    return plainAxiosInstance.get(CLAIM_URL + `?plan_id=${planParams.planId}&email=${planParams.email}`);
  }

  getClaimForm(planSaleId, claimProductId) {
    return plainAxiosInstance.get(CLAIM_URL + `/${planSaleId}?claim_product_id=${claimProductId}`);
  }

  createClaim(planSaleId, claimProductId, formData) {
    return plainAxiosInstance.post(CLAIM_URL + `/${planSaleId}/claim?claim_product_id=${claimProductId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ClaimService();
