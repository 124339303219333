function productMatchesSearch(variant, search) {
  if (search === "") {
    return true;
  }

  return (
    variant.title.toLowerCase().includes(search.toLowerCase()) ||
    variant.productType.toLowerCase().includes(search.toLowerCase()) ||
    variant.vendor.toLowerCase().includes(search.toLowerCase())
  );
}

class Product {
  constructor(id, enabled) {
    this.id = id;
    this.enabled = enabled;
  }
}

function filterProduct(filters, search, product) {
  return (
    (product.tags.some((tag) => filters.tags.includes(tag)) || filters.tags.length === 0) &&
    (filters.vendors.includes(product.vendor) || filters.vendors.length === 0) &&
    (filters.productTypes.includes(product.productType) || filters.productTypes.length === 0) &&
    (filters.offerStatuses.includes(product.offerStatus) || filters.offerStatuses.length === 0) &&
    productMatchesSearch(product, search)
  );
}

function filterProducts(filters, search, products) {
  const filteredProducts = [...products].filter(
    (p) => filterProduct(filters, search, p) || p.variants.some((v) => filterProduct(filters, search, v))
  );

  return filteredProducts;
}

export { filterProducts, Product };
