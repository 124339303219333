<template>
  <div v-if="planIsActive">
    <h1 class="text-xl py-4 pl-2 font-extrabold tracking-tight text-gray-900">Produits assurés</h1>
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <li
        v-for="product in this.plan.product"
        v-bind:key="product.id"
        class="bg-white col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-4">
          <div class="flex flex-row justify-between mb-2">
            <div
              v-if="plan.composedProductType && planIsActive"
              @click="updateItem(product)"
              class="w-5 h-5 text-primary cursor-pointer hover:underline hover:underline-offset-1"
              aria-hidden="true"
            >
              <span>Modifier</span>
            </div>
            <TrashIcon
              v-if="this.plan.product?.length > 1 && planIsActive"
              @click="deleteItem(product)"
              class="w-5 h-5 text-red-400 cursor-pointer hover:text-red-900"
              aria-hidden="true"
            />
          </div>
          <img
            class="max-w-32 max-h-32 object-contain flex-shrink-0 mx-auto"
            v-bind:src="`${product.imageUrl}`"
            alt=""
          />
          <div class="my-4">
            <h3 class="mt-1 text-gray-900 text-md font-bold">
              {{ product.title }}
            </h3>
          </div>
          <div>
            <div v-if="product.itemType" class="mt-1 flex flex-row justify-between text-sm">
              <span>Catégorie</span>
              <span class="font-bold">{{ $t(product.itemType) }}</span>
            </div>
            <div class="mt-1 flex flex-row justify-between text-sm">
              <span>Marque</span>
              <span class="font-bold">{{ product.vendor }}</span>
            </div>
            <div v-if="product.model" class="mt-1 flex flex-row justify-between text-sm">
              <span>Modèle</span>
              <span class="font-bold">{{ product.model }}</span>
            </div>
            <div class="mt-1 flex flex-row justify-between text-sm">
              <span>Prix du produit</span>
              <span class="font-bold">{{ formatPrice(product.price) }}</span>
            </div>
            <div class="mt-1 flex flex-row justify-between text-sm">
              <span>Numéro de série</span>
              <span class="font-bold">{{ product.referenceId }}</span>
            </div>
            <div class="mt-1 flex flex-row justify-between text-sm">
              <span>Date d'achat</span>
              <span class="font-bold">{{ product.purchaseDate }}</span>
            </div>
          </div>
        </div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <div class="cursor-pointer w-0 flex-1 flex" v-if="planIsActive">
            <router-link
              class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              v-bind:to="`/plans/${plan.id}/claim_form/${product.id}`"
            >
              <ShieldCheckIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span class="ml-3">Déclarer un sinistre</span>
            </router-link>
          </div>
        </div>
      </li>
      <li
        v-if="plan.composedProductType && planIsActive"
        class="bg-white col-span-1 flex flex-col text-center rounded-lg shadow divide-y divide-gray-200 rounded-md ring-offset-2 ring-2 rounded-md ring-primary cursor-pointer group"
        @click="addItem"
      >
        <div class="py-10 flex flex-col items-center justify-center min-h-full">
          <PlusIcon class="w-10 h-10 text-primary-dark font-normal" aria-hidden="true" />
          <span class="mt-4 text-primary-dark text-md font-semibold">Enregistrer un produit</span>
        </div>
      </li>
    </ul>
  </div>
  <DeleteProductItemModal
    v-bind:isOpen="isDeleteModalOpen"
    v-bind:productItem="deleteProductItem"
    v-bind:planSaleId="plan.id"
    @closeDeleteProductItemModal="closeDeleteProductItemModal"
    @refreshPlanSale="refreshPlanSale"
  />
  <AddProductItemModal
    v-bind:isOpen="isAddModalOpen"
    v-bind:plan="plan"
    @closeAddProductItemModal="closeAddProductItemModal"
    @refreshPlanSale="refreshPlanSale"
  />
  <UpdateProductItemModal
    v-bind:isOpen="isUpdateModalOpen"
    v-bind:plan="plan"
    v-bind:productItem="updateProductItem"
    @closeUpdateProductItemModal="closeUpdateProductItemModal"
    @refreshPlanSale="refreshPlanSale"
  />
</template>

<script>
import { formatPrice } from "@/utils/price_formatter";
import { mapState } from "vuex";
import { ShieldCheckIcon, TrashIcon, PlusIcon } from "@heroicons/vue/solid";
import DeleteProductItemModal from "@/views/plan/DeleteProductItemModal";
import UpdateProductItemModal from "@/views/plan/UpdateProductItemModal";
import AddProductItemModal from "@/views/plan/AddProductItemModal";

export default {
  props: ["plan"],

  components: {
    ShieldCheckIcon,
    TrashIcon,
    PlusIcon,
    DeleteProductItemModal,
    AddProductItemModal,
    UpdateProductItemModal,
  },

  data() {
    return {
      isDeleteModalOpen: false,
      isUpdateModalOpen: false,
      isAddModalOpen: false,
      deleteProductItem: null,
      updateProductItem: null,
    };
  },

  computed: {
    ...mapState("customer", ["plans"]),
    planIsActive() {
      return this.plan.status === "Active";
    },
  },

  methods: {
    formatPrice,

    deleteItem(product) {
      this.deleteProductItem = product;
      this.isDeleteModalOpen = true;
    },

    addItem() {
      this.isAddModalOpen = true;
    },

    updateItem(product) {
      this.updateProductItem = product;
      this.isUpdateModalOpen = true;
    },

    closeDeleteProductItemModal() {
      this.isDeleteModalOpen = false;
    },

    closeUpdateProductItemModal() {
      this.isUpdateModalOpen = false;
    },

    closeAddProductItemModal() {
      this.isAddModalOpen = false;
    },

    refreshPlanSale() {
      this.$emit("refreshPlanSale");
    },
  },

  watch: {
    plan: {
      deep: true,
    },
  },
};
</script>
