<template>
  <div style="bg-white">
    <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <div class="rounded-2xl px-6 py-12 sm:p-12">
        <div class="mx-auto max-w-xl lg:max-w-none">
          <div class="text-center">
            <h2 class="text-6xl font-extralight tracking-tight text-gray-900">
              {{ marketing.subHeadline }}
            </h2>
          </div>
          <div class="mx-auto mt-16 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
            <div
              v-for="(item, idx) in this.marketing.bulletPoints"
              :key="idx"
              :class="{
                'lg:col-start-2': idx === this.marketing.bulletPoints.length - 1,
              }"
              class="text-center sm:flex sm:text-left lg:block lg:text-center"
            >
              <div class="sm:flex-shrink-0">
                <div
                  class="rounded-full p-2 inline-block"
                  :style="{
                    'background-color': iconBackgroundColor,
                  }"
                >
                  <component
                    :is="item.icon"
                    class="w-8 h-8"
                    :style="{
                      color: iconColor,
                    }"
                  />
                </div>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                <h3 class="text-xl font-medium text-gray-900">
                  {{ item.title }}
                </h3>
                <p class="mt-2 text-gray-500">
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BookOpenIcon,
  CurrencyEuroIcon,
  TrendingDownIcon,
  ChartPieIcon,
  CursorClickIcon,
  ClockIcon,
  LockOpenIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    BookOpenIcon,
    CurrencyEuroIcon,
    TrendingDownIcon,
    ChartPieIcon,
    CursorClickIcon,
    ClockIcon,
    LockOpenIcon,
  },

  props: {
    marketing: Object,
    iconBackgroundColor: String,
    iconColor: String,
  },
};
</script>
