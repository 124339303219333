<template>
  <div class="col-span-6 sm:col-span-3">
    <form>
      <p class="block text-sm text-gray-600">Adresse</p>
      <div class="mb-4">
        <input
          id="autocomplete"
          type="text"
          placeholder="58 rue de la Victoire, Paris, France"
          class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { isStringValid } from "@/utils/validation";

export default {
  props: {
    initialAddress: {
      type: Object,
      default: () => ({
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
      }),
    },
  },

  data: () => {
    return {
      address: {
        street: "",
        city: "",
        province: "",
        country: "",
        zipCode: "",
      },
    };
  },
  mounted() {
    if (window.google) {
      const google = window.google;
      this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"));
      this.autocomplete.setComponentRestrictions({
        country: ["fr", "mq", "gp", "re"],
      });
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        let ac = place.address_components;

        const streetNumber = this.findDataShortName(ac, "street_number");
        const route = this.findDataShortName(ac, "route");
        this.address.street =
          isStringValid(streetNumber) && isStringValid(route) ? `${streetNumber} ${route}` : place.formatted_address;
        this.address.city = this.findDataShortName(ac, "locality");
        this.address.province = this.findDataShortName(ac, "administrative_area_level_1");
        this.address.country = this.findDataShortName(ac, "country");
        this.address.zipCode = this.findDataShortName(ac, "postal_code");

        this.$emit("newAddress", this.address);
      });
    }
  },

  watch: {
    initialAddress: {
      handler(newValue) {
        if (isStringValid(newValue.street) && isStringValid(newValue.city) && isStringValid(newValue.country)) {
          // Concaténez les parties de l'adresse pour former une adresse complète
          const fullAddress = `${newValue.street}, ${newValue.city}, ${newValue.province}, ${newValue.zipCode}, ${newValue.country}`;
          this.$nextTick(() => {
            document.getElementById("autocomplete").value = fullAddress;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    findDataShortName(ac, key) {
      return ac?.find((item) => item.types.includes(key))?.short_name;
    },
  },
};
</script>
